import { EDropdownActionType } from '../../actions/dropdown';
import { IDropdownState } from '../../types/dropdown';
import { TReduxActions } from '../../types/redux';

export const defaultState: IDropdownState = {
  open: false,
  hoveredRubric: null,
  activeRubric: null,
  activeCloseTimeout: null,
  activeOpenTimeout: null,
  fskBanner: null,
  fskDropdownMenuItem: null,
  buildersProjectsLocations: [],
  suburbanBlackFriday: {
    isActive: false,
    url: null,
  },
  isSuburbanFlightRkActive: false,
};

export function dropdownReducer(state: IDropdownState = defaultState, action: TReduxActions): IDropdownState {
  switch (action.type) {
    case EDropdownActionType.Open:
      return {
        ...state,
        open: true,
      };
    case EDropdownActionType.Close:
      return {
        ...state,
        open: false,
      };
    case EDropdownActionType.SetHoveredRubric:
      return {
        ...state,
        hoveredRubric: action.payload,
      };
    case EDropdownActionType.SetActiveOpenTimeout:
      return {
        ...state,
        activeOpenTimeout: action.payload,
      };
    case EDropdownActionType.SetActiveCloseTimeout:
      return {
        ...state,
        activeCloseTimeout: action.payload,
      };
    default:
      return state;
  }
}
