import { CustomTooltip } from '@cian/ui-kit';
import * as React from 'react';

import { trackingMobileAppClick } from './tracking';
import { getTooltipCloseFromCookie, setTooltipCloseCookies } from './utils';
import { MobileAppLinkLayout } from '../../components/MobileAppLinkLayout';

export const MobileAppDesktopContainer: React.FC = () => {
  const [isOpen, setOpen] = React.useState(false);
  const [isIpotekaPage, setIsIpotekaPage] = React.useState(false);

  const tooltipRef = React.useRef<HTMLDivElement>(null);

  const handleClose = React.useCallback(() => {
    if (!isOpen) {
      setOpen(false);
      setTooltipCloseCookies();
    }
  }, [tooltipRef]);

  const handleClick = React.useCallback(() => {
    handleClose();
    trackingMobileAppClick();
  }, [tooltipRef]);

  React.useEffect(() => {
    const tooltipCloseFromCookie = getTooltipCloseFromCookie();

    setOpen(!tooltipCloseFromCookie);
  }, [tooltipRef]);

  React.useEffect(() => {
    // не показываем тултип на стр ипотеки
    setIsIpotekaPage(window.location.pathname === '/ipoteka-main/');
  }, []);

  return (
    <MobileAppLinkLayout onClick={handleClick}>
      <span ref={tooltipRef}>Приложение Циан</span>
      {!isIpotekaPage && (
        <CustomTooltip
          open={isOpen}
          onClose={handleClose}
          content="В&nbsp;приложении — быстрее и&nbsp;удобнее"
          placement="bottom-start"
          theme="blue"
          arrow={true}
          closeButton
          anchorRef={tooltipRef}
          maxWidth={320}
        />
      )}
    </MobileAppLinkLayout>
  );
};
